import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/Layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout location={{
      pathname: "/test"
    }} mdxType="Layout">
      <h1>{`test`}</h1>
      <p>{`hello`}</p>
      <h1>{`woah`}</h1>
      <p>{`woah`}</p>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      